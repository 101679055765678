<template>
  <v-container fluid>
    <v-card flat outlined class="pa-4 pa-sm-6">
      <v-sheet class="mb-5 text-h6 d-flex align-center" color="transparent">
        <v-btn icon class="me-2" @click="$router.go(-1)">
          <v-icon> mdi-arrow-left </v-icon>
        </v-btn>
        Vehicle Information

        <v-spacer></v-spacer>

        <v-chip
          dark
          class="mx-1"
          :color="vehicle.is_in_use ? 'green' : 'red'"
          small
        >
          {{ vehicle.is_in_use ? "Enabled" : "Disabled" }}
        </v-chip>
      </v-sheet>
      <v-row class="ms-md-12" v-if="loading">
        <v-col cols="12" sm="6" md="4" v-for="i in 15" :key="i">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-sheet v-else-if="vehicle" color="transparent">
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary">
                  mdi-ticket-confirmation-outline
                </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ vehicle.vehicle_no }}</v-list-item-title>
                <v-list-item-subtitle>Vehicle Number</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-account-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.staff
                      ? vehicle.staff.first_name + " " + vehicle.staff.last_name
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>Driver</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  $_getHistory(vehicle.vehicle_history, 'UP_DRIV').length > 0
                "
              >
                <v-btn
                  icon
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_DRIV');
                    historyDialog = true;
                    history_type = 'UP_DRIV';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-shape-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.category ? vehicle.category : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Category</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-engine-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.engine_no ? vehicle.engine_no : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Engine Number</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-car-back </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.chassis_no ? vehicle.chassis_no : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Chassis Number</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>

          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-car-settings </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.model ? vehicle.model : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Model</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-car-wrench </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.make ? vehicle.make : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Make</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-shape-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.class ? vehicle.class : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Class</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-check-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.registration_date
                      ? vehicle.registration_date
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>Registration Date</v-list-item-subtitle>
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>

        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-clock-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.oil_changed_date ? vehicle.oil_changed_date : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Last Oil Changed Date</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-counter </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.oil_changed_km ? vehicle.oil_changed_km : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Last Oil Changed Meter (km)</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action class="align-center">
                <v-btn
                  icon
                  v-if="
                    $_getHistory(vehicle.vehicle_history, 'UP_OIL').length > 0
                  "
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_OIL');
                    historyDialog = true;
                    history_type = 'UP_OIL';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
                <v-tooltip
                  bottom
                  v-if="
                    vehicle.current_meter_reading >
                    vehicle.oil_changed_km +
                      vehicle.oil_change_interval_km -
                      vehicle.oil_change_notification_km
                  "
                  :color="
                    vehicle.current_meter_reading >
                    vehicle.oil_changed_km + vehicle.oil_change_interval_km
                      ? 'error'
                      : 'warning'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        vehicle.current_meter_reading >
                        vehicle.oil_changed_km + vehicle.oil_change_interval_km
                          ? 'error'
                          : 'warning'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      vehicle.current_meter_reading >
                      vehicle.oil_changed_km + vehicle.oil_change_interval_km
                        ? `Oil change overdue. Act now.`
                        : `Oil change due before ${
                            vehicle.mechanical_serviced_km +
                            vehicle.mechanical_service_interval_km
                          } km.`
                    }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-counter </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.oil_change_interval_km + vehicle.oil_changed_km }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Next Oil to be Change Meter (km)</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-clock-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.mechanical_serviced_date
                      ? vehicle.mechanical_serviced_date
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Last Mechanical Service Date</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-counter </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.mechanical_serviced_km
                      ? vehicle.mechanical_serviced_km
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Last Mechanical Service Meter (km)</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action class="align-center">
                <v-btn
                  icon
                  v-if="
                    $_getHistory(vehicle.vehicle_history, 'UP_MECH').length > 0
                  "
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_MECH');
                    historyDialog = true;
                    history_type = 'UP_MECH';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
                <v-tooltip
                  bottom
                  v-if="
                    vehicle.current_meter_reading >
                    vehicle.mechanical_serviced_km +
                      vehicle.mechanical_service_interval_km -
                      vehicle.mechanical_service_notification_km
                  "
                  :color="
                    vehicle.current_meter_reading >
                    vehicle.mechanical_serviced_km +
                      vehicle.mechanical_service_interval_km
                      ? 'error'
                      : 'warning'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        vehicle.current_meter_reading >
                        vehicle.mechanical_serviced_km +
                          vehicle.mechanical_service_interval_km
                          ? 'error'
                          : 'warning'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      vehicle.current_meter_reading >
                      vehicle.mechanical_serviced_km +
                        vehicle.mechanical_service_interval_km
                        ? `Service overdue. Act now.`
                        : `Service due before ${
                            vehicle.mechanical_serviced_km +
                            vehicle.mechanical_service_interval_km
                          } km.`
                    }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-counter </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.mechanical_service_interval_km +
                    vehicle.mechanical_serviced_km
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Next Mechanical Service Meter (km)</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-end-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.revenue_license_date
                      ? vehicle.revenue_license_date
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Revenue License Date</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action class="align-center">
                <v-btn
                  icon
                  v-if="
                    $_getHistory(vehicle.vehicle_history, 'UP_LICE').length > 0
                  "
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_LICE');
                    historyDialog = true;
                    history_type = 'UP_LICE';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
                <v-tooltip
                  bottom
                  v-if="$_daysDifference(vehicle.revenue_license_date) < 30"
                  :color="
                    $_daysDifference(vehicle.revenue_license_date) > 0
                      ? 'warning'
                      : 'error'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        $_daysDifference(vehicle.revenue_license_date) > 0
                          ? 'warning'
                          : 'error'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      $_daysDifference(vehicle.revenue_license_date) > 0
                        ? `Revenue license expires in ${$_daysDifference(
                            vehicle.revenue_license_date
                          )} days.`
                        : `Revenue license expired.`
                    }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-end-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.insurance_date ? vehicle.insurance_date : "N/A" }}
                </v-list-item-title>
                <v-list-item-subtitle>Insurance Date</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="align-center">
                <v-btn
                  icon
                  v-if="
                    $_getHistory(vehicle.vehicle_history, 'UP_INSU').length > 0
                  "
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_INSU');
                    historyDialog = true;
                    history_type = 'UP_INSU';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
                <v-tooltip
                  bottom
                  v-if="$_daysDifference(vehicle.insurance_date) < 30"
                  :color="
                    $_daysDifference(vehicle.insurance_date) > 0
                      ? 'warning'
                      : 'error'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        $_daysDifference(vehicle.insurance_date) > 0
                          ? 'warning'
                          : 'error'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      $_daysDifference(vehicle.insurance_date) > 0
                        ? `Insurance expires in ${$_daysDifference(
                            vehicle.insurance_date
                          )} days.`
                        : `Insurance expired.`
                    }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-calendar-end-outline </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{
                    vehicle.emission_test_date
                      ? vehicle.emission_test_date
                      : "N/A"
                  }}
                </v-list-item-title>
                <v-list-item-subtitle>Emission Test Date</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action class="align-center">
                <v-btn
                  icon
                  v-if="
                    $_getHistory(vehicle.vehicle_history, 'UP_EMIS').length > 0
                  "
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_EMIS');
                    historyDialog = true;
                    history_type = 'UP_EMIS';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
                <v-tooltip
                  bottom
                  v-if="$_daysDifference(vehicle.emission_test_date) < 30"
                  :color="
                    $_daysDifference(vehicle.emission_test_date) > 0
                      ? 'warning'
                      : 'error'
                  "
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      :color="
                        $_daysDifference(vehicle.emission_test_date) > 0
                          ? 'warning'
                          : 'error'
                      "
                      v-bind="attrs"
                      v-on="on"
                    >
                      mdi-alert-outline
                    </v-icon>
                  </template>
                  <span>
                    {{
                      $_daysDifference(vehicle.emission_test_date) > 0
                        ? `Emission test due in ${$_daysDifference(
                            vehicle.emission_test_date
                          )} days.`
                        : `Emission test expired.`
                    }}
                  </span>
                </v-tooltip>
              </v-list-item-action>
            </v-list-item>
          </v-col>
        </v-row>
        <v-divider class="my-5"></v-divider>
        <v-row>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-counter </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.current_meter_box_id }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Current Meter Box ID</v-list-item-subtitle
                >
              </v-list-item-content>
              <v-list-item-action
                v-if="
                  $_getHistory(vehicle.vehicle_history, 'UP_METER').length > 0
                "
              >
                <v-btn
                  icon
                  @click="
                    history = $_getHistory(vehicle.vehicle_history, 'UP_METER');
                    historyDialog = true;
                    history_type = 'UP_METER';
                  "
                >
                  <v-icon color="grey lighten-1">mdi-history</v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
          </v-col>
          <v-col cols="12" sm="6" md="4" class="py-0">
            <v-list-item two-line>
              <v-list-item-icon>
                <v-icon color="primary"> mdi-counter </v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>
                  {{ vehicle.current_meter_reading }}
                </v-list-item-title>
                <v-list-item-subtitle
                  >Current Meter Reading</v-list-item-subtitle
                >
              </v-list-item-content>
            </v-list-item>
          </v-col>
        </v-row>
      </v-sheet>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        $_checkPermission(auth.permissions, 'Manage Vehicle Document', 'Read')
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent"> Documents </v-sheet>
      <v-row class="ms-md-12" v-if="docGetLoading">
        <v-col cols="12" sm="6" md="4" v-for="i in 6" :key="i + 30">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-row v-else-if="documets && documets.length > 0">
        <v-col cols="12" sm="6" md="4" v-for="doc in documets" :key="doc.uuid">
          <v-card outlined color="">
            <v-list-item two-line>
              <v-list-item-action>
                <v-btn icon x-large>
                  <v-icon x-large color="primary"
                    >mdi-file-document-outline</v-icon
                  >
                </v-btn>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title> {{ doc.name }} </v-list-item-title>
                <v-list-item-subtitle>
                  {{ $_formatTimestamp(doc.modified_at) }}
                </v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-menu
                  :close-on-content-click="true"
                  :nudge-width="200"
                  :max-width="200"
                  offset-x
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn icon v-bind="attrs" v-on="on">
                      <v-icon color="grey lighten-1">
                        mdi-dots-vertical
                      </v-icon>
                    </v-btn>
                  </template>

                  <v-card outlined elevation="0" class="imso-menu">
                    <v-list>
                      <v-list-item
                        link
                        @click="
                          selectedDoc = doc;
                          docViewDialog = true;
                        "
                      >
                        <v-list-item-title>Open</v-list-item-title>
                        <v-icon color="grey lighten-1">
                          mdi-open-in-new
                        </v-icon>
                      </v-list-item>
                      <v-list-item
                        link
                        @click="downloadBase64File(doc.binary_file, doc.name)"
                      >
                        <v-list-item-title>Download</v-list-item-title>
                        <v-icon color="grey lighten-1"> mdi-download </v-icon>
                      </v-list-item>
                      <v-list-item
                        v-if="
                          $_checkPermission(
                            auth.permissions,
                            'Manage Vehicle Document',
                            'Delete'
                          ) ||
                          (auth.uuid == vehicle.driver_uuid &&
                            $_checkPermission(
                              auth.permissions,
                              'Manage Vehicle Document',
                              'Manage Personal'
                            ))
                        "
                        link
                        @click="
                          selectedDoc = doc;
                          deleteDocDialog = true;
                        "
                      >
                        <v-list-item-title>Delete</v-list-item-title>
                        <v-icon color="error"> mdi-delete </v-icon>
                      </v-list-item>
                    </v-list>
                  </v-card>
                </v-menu>
              </v-list-item-action>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <v-row v-else class="text-center text--secondary">
        <v-col> No documents. </v-col>
      </v-row>
    </v-card>

    <v-card
      flat
      outlined
      class="pa-4 pa-sm-6 mt-8"
      v-if="
        $_checkPermission(auth.permissions, 'Manage Vehicle', 'Delete') ||
        $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
        (auth.uuid == vehicle.driver_uuid &&
          $_checkPermission(
            auth.permissions,
            'Manage Vehicle',
            'Manage Personal'
          )) ||
        $_checkPermission(
          auth.permissions,
          'Manage Vehicle Document',
          'Create'
        ) ||
        (auth.uuid == vehicle.driver_uuid &&
          $_checkPermission(
            auth.permissions,
            'Manage Vehicle Document',
            'Manage Personal'
          ))
      "
    >
      <v-sheet class="mb-5 text-h6" color="transparent">
        Administrator Actions
      </v-sheet>
      <v-row v-if="loading">
        <v-col cols="12" v-for="i in 8" :key="i + 14">
          <v-skeleton-loader type="sentences"></v-skeleton-loader>
        </v-col>
      </v-row>
      <v-list color="actionList" v-else>
        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Edit Vehicle</v-list-item-title>
            <v-list-item-subtitle>
              Edit vehicle information in the IMSO database.
              <span class="d-none d-md-inline">
                This action will not maintain a history of changes, making it
                suitable for correcting errors or updating details when tracking
                changes is not necessary.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="editItem(vehicle)"
            >
              <v-icon left> mdi-pencil </v-icon>
              Edit
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Document',
              'Create'
            ) ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle Document',
                'Manage Personal'
              ))
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(
              auth.permissions,
              'Manage Vehicle Document',
              'Create'
            ) ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle Document',
                'Manage Personal'
              ))
          "
        >
          <v-list-item-content>
            <v-list-item-title>Upload Documents</v-list-item-title>
            <v-list-item-subtitle>
              Add or update documents related to the Vehicle.
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="docUploadDialog = true"
            >
              <v-icon left>mdi-upload</v-icon>
              Upload
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        >
          <v-list-item-content>
            <v-list-item-title>Update Emission Test Date</v-list-item-title>
            <v-list-item-subtitle>
              Update the vehicle with a new emission test date.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                updateEmissionDialog = true;
                oldValue = vehicle.emission_test_date;
              "
            >
              <v-icon left>mdi-calendar-edit</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        >
          <v-list-item-content>
            <v-list-item-title>Update Insurance Date</v-list-item-title>
            <v-list-item-subtitle>
              Update the vehicle with a new insurance date.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                updateInsuranceDialog = true;
                oldValue = vehicle.insurance_date;
              "
            >
              <v-icon left>mdi-calendar-edit</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        >
          <v-list-item-content>
            <v-list-item-title>Update Revenue License Date</v-list-item-title>
            <v-list-item-subtitle>
              Update the vehicle with a new revenue license date.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                updateLicenseDialog = true;
                oldValue = vehicle.revenue_license_date;
              "
            >
              <v-icon left>mdi-calendar-edit</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        >
          <v-list-item-content>
            <v-list-item-title>Update Oil Change</v-list-item-title>
            <v-list-item-subtitle>
              Update the vehicle's oil change mileage.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                oilChangeDialog = true;
                oldValue = vehicle.oil_changed_km;
              "
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update') ||
            (auth.uuid == vehicle.driver_uuid &&
              $_checkPermission(
                auth.permissions,
                'Manage Vehicle',
                'Manage Personal'
              ))
          "
        >
          <v-list-item-content>
            <v-list-item-title>Update Mechanical Service</v-list-item-title>
            <v-list-item-subtitle>
              Update the vehicle's mechanical service mileage.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="
                mechanicalServiceDialog = true;
                oldValue = vehicle.mechanical_serviced_km;
              "
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Change Driver</v-list-item-title>
            <v-list-item-subtitle>
              Assign a new driver to the vehicle.
              <span class="d-none d-md-inline">
                The history of changes will be maintained.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="secondary"
              outlined
              @click="openDriverChange"
            >
              <v-icon left>mdi-update</v-icon>
              Update
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')"
        >
          <v-list-item-content>
            <v-list-item-title>Change Vehicle Meter Box</v-list-item-title>
            <v-list-item-subtitle>
              Change the vehicle meter box.
              <span class="d-none d-md-inline">
                Updating this will adjust the displayed meter reading and ensure
                accurate tracking of the vehicle's usage across the app.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="updateMeterBoxDialog = true"
            >
              <v-icon left>mdi-swap-horizontal</v-icon>
              Change
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="
            vehicle.is_in_use &&
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Disable Vehicle</v-list-item-title>
            <v-list-item-subtitle>
              Prevent staff from selecting the vehicle in other parts of the
              app.
              <span class="d-none d-md-inline">
                Disabled vehicles will not be visible or accessible for
                selection elsewhere in the app.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="disableVehicleDialog = true"
            >
              <v-icon left>mdi-close</v-icon>
              Disable
            </v-btn>
          </v-list-item-action>
        </v-list-item>

        <v-list-item
          three-line
          v-else-if="
            $_checkPermission(auth.permissions, 'Manage Vehicle', 'Update')
          "
        >
          <v-list-item-content>
            <v-list-item-title>Enable Vehicle</v-list-item-title>
            <v-list-item-subtitle>
              Make the vehicle available for selection by staff.
              <span class="d-none d-md-inline">
                Once enabled, the vehicle will be visible and accessible for
                selection in other parts of the app.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="primary"
              outlined
              @click="enableVehicleDialog = true"
            >
              <v-icon left>mdi-check</v-icon>
              Enable
            </v-btn>
          </v-list-item-action>
        </v-list-item>
        <v-divider
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Delete')"
        ></v-divider>

        <v-list-item
          three-line
          v-if="$_checkPermission(auth.permissions, 'Manage Vehicle', 'Delete')"
        >
          <v-list-item-content>
            <v-list-item-title> Delete Vehicle</v-list-item-title>
            <v-list-item-subtitle>
              Delete vehicle information from the app.
              <span class="d-none d-md-inline">
                This action cannot be undone through the app, and only a
                database admin can restore it. All associated data will be
                hidden from the app.
              </span>
            </v-list-item-subtitle>
          </v-list-item-content>
          <v-list-item-action>
            <v-btn
              depressed
              color="error"
              outlined
              @click="deleteVehicleDialog = true"
            >
              <v-icon left>mdi-delete</v-icon>
              Delete
            </v-btn>
          </v-list-item-action>
        </v-list-item>
      </v-list>
    </v-card>

    <!-- Delete vehicle dialog -->
    <v-dialog v-model="deleteVehicleDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this vehicle? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteVehicle()"
            :loading="deleteVehicleLoading"
          >
            Delete Vehicle
          </v-btn>
          <v-btn color="primary" text @click="deleteVehicleDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Vehicle emission test dialog -->
    <v-dialog v-model="updateEmissionDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Update Emission Test Date </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="emissionForm">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu1"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Emission Test Date"
                  v-model="newEmissionDate"
                  :rules="[rules.required, rules.sameValue, rules.futureDate]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newEmissionDate"
                color="primary"
                no-title
                @input="menu1 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateEmissionDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateEmissionX"
            :loading="updateEmissionLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Vehicle insurance dialog -->
    <v-dialog v-model="updateInsuranceDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Update Insurance Date </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="insuranceForm">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu2"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Insurance Date"
                  v-model="newInsuranceDate"
                  :rules="[rules.required, rules.sameValue, rules.futureDate]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newInsuranceDate"
                color="primary"
                no-title
                @input="menu2 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateInsuranceDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateInsuranceX"
            :loading="updateInsuranceLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!--Vehicle license dialog -->
    <v-dialog v-model="updateLicenseDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5">
          Update Revenue License Date
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="licenseForm">
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu3"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Revenue License Date"
                  v-model="newLicenseDate"
                  :rules="[rules.required, rules.sameValue, rules.futureDate]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newLicenseDate"
                color="primary"
                no-title
                @input="menu3 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateLicenseDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateLicenseX"
            :loading="updateLicenseLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Oil Change dialog -->
    <v-dialog v-model="oilChangeDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Update Oil Change Meter </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="oilChangeForm">
            <v-text-field
              flat
              outlined
              type="number"
              label="Oil Changed Meter Reading (km)"
              v-model="newOilChangeKm"
              :rules="[rules.required, rules.sameValue]"
            ></v-text-field>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu4"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Oil Changed Date"
                  v-model="newOilChangeDate"
                  :rules="[rules.required, rules.todayOrPastDate]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newOilChangeDate"
                color="primary"
                no-title
                @input="menu4 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="oilChangeDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="oilChangeX"
            :loading="oilChangeLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Mechanical Service dialog -->
    <v-dialog v-model="mechanicalServiceDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5">
          Update Mechanical Service Meter
        </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="mechanicalServiceForm">
            <v-text-field
              flat
              outlined
              type="number"
              label="Mechanical Service Meter Reading (km)"
              v-model="newMechanicalServiceKm"
              :rules="[rules.required, rules.sameValue]"
            ></v-text-field>
            <v-menu
              :close-on-content-click="false"
              transition="scale-transition"
              min-width="auto"
              v-model="menu5"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  flat
                  outlined
                  label="Mechanical Service Date"
                  v-model="newMechanicalServiceDate"
                  :rules="[rules.required, rules.todayOrPastDate]"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="newMechanicalServiceDate"
                color="primary"
                no-title
                @input="menu5 = false"
              ></v-date-picker>
            </v-menu>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="secondary"
            text
            @click="mechanicalServiceDialog = false"
          >
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="mechanicalServiceX"
            :loading="mechanicalServiceLoading"
          >
            Update
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Update driver dialog -->
    <v-dialog v-model="changeDriverDialog" persistent max-width="400">
      <v-card outlined>
        <v-card-title class="text-h5"> Change Driver </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="driverForm">
            <v-text-field
              flat
              outlined
              readonly
              label="Current Driver"
              :value="
                vehicle.staff
                  ? vehicle.staff.first_name + ' ' + vehicle.staff.last_name
                  : null
              "
            ></v-text-field>
            <v-skeleton-loader
              type="image"
              height="50"
              v-if="dataLoading"
            ></v-skeleton-loader>
            <v-autocomplete
              auto-select-first
              v-else
              flat
              v-model="newDriver"
              :rules="[rules.required, rules.sameValue]"
              :items="drivers"
              item-text="name"
              item-value="uuid"
              label="New Driver"
              outlined
            ></v-autocomplete>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="changeDriverDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="changeDriverX"
            :loading="changeDriverLoading"
          >
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Enable vehicle confirmation dialog -->
    <v-dialog v-model="enableVehicleDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to enable this Vehicle?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="enableVehicleDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="enableVehicleX()"
            :loading="enableVehicleLoading"
          >
            Enable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- update vehicle meter-box dialog -->
    <v-dialog v-model="updateMeterBoxDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text> Are you sure? </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="updateMeterBoxDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="updateMeterBoxX()"
            :loading="updateMeterBoxLoading"
          >
            Change
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Disable vehicle confirmation dialog -->
    <v-dialog v-model="disableVehicleDialog" persistent max-width="290">
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to disable this Vehicle?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="disableVehicleDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="disableVehicleX()"
            :loading="disableVehicleLoading"
          >
            Disable
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- History dialog -->
    <v-dialog v-model="historyDialog" persistent max-width="480" scrollable>
      <v-card outlined>
        <v-card-title class="text-h5">
          <v-icon class="me-2">mdi-history</v-icon> History
          <v-spacer></v-spacer>
          <v-btn icon text @click="historyDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-timeline dense>
            <v-timeline-item
              color="grey lighten-1"
              left
              small
              fill-dot
              v-for="(record, i) in history"
              :key="'h' + i"
            >
              <v-list-item class="pa-0" v-if="history_type == 'UP_OIL'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.oil_changed_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Changed Date </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.oil_changed_km }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Changed Meter Reading (km)
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else-if="history_type == 'UP_MECH'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.mechanical_serviced_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Serviced Date </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ record.mechanical_serviced_km }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Serviced Meter Reading (km)
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else>
                <v-list-item-content class="pa-0">
                  <v-list-item-title v-if="history_type == 'UP_DRIV'">
                    {{ record.driver_name }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_METER'">
                    {{ record.meter_box_id }} </v-list-item-title
                  ><v-list-item-title v-if="history_type == 'UP_DRIV'">
                    {{ record.driver_name }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_LICE'">
                    {{ record.revenue_license_date }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_INSU'">
                    {{ record.insurance_date }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_EMIS'">
                    {{ record.emission_test_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{
                      i == 0
                        ? "Beginning"
                        : $_formatTimestamp(history[i - 1].created_at)
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-timeline-item>
            <v-timeline-item color="grey lighten-1" left small fill-dot>
              <v-list-item class="pa-0" v-if="history_type == 'UP_OIL'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ vehicle.oil_changed_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Changed Date </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ vehicle.oil_changed_km }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Changed Meter Reading (km)
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else-if="history_type == 'UP_MECH'">
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ vehicle.mechanical_serviced_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle> Serviced Date </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-content class="pa-0">
                  <v-list-item-title>
                    {{ vehicle.mechanical_serviced_km }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    Serviced Meter Reading (km)
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
              <v-list-item class="pa-0" v-else>
                <v-list-item-content class="pa-0">
                  <v-list-item-title v-if="history_type == 'UP_DRIV'">
                    {{
                      vehicle.staff.first_name + " " + vehicle.staff.last_name
                    }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_LICE'">
                    {{ vehicle.revenue_license_date }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_METER'">
                    {{ vehicle.current_meter_box_id }} </v-list-item-title
                  ><v-list-item-title v-if="history_type == 'UP_LICE'">
                    {{ vehicle.revenue_license_date }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_INSU'">
                    {{ vehicle.insurance_date }}
                  </v-list-item-title>
                  <v-list-item-title v-if="history_type == 'UP_EMIS'">
                    {{ vehicle.emission_test_date }}
                  </v-list-item-title>
                  <v-list-item-subtitle v-if="history.length > 0">
                    {{
                      history.length > 0
                        ? $_formatTimestamp(
                            history[history.length - 1].created_at
                          )
                        : "Current"
                    }}
                  </v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-timeline-item>
          </v-timeline>
        </v-card-text>
      </v-card>
    </v-dialog>

    <!-- File Upload dialog -->
    <v-dialog v-model="docUploadDialog" persistent max-width="480">
      <v-card outlined>
        <v-card-title class="text-h5"> Upload Document </v-card-title>
        <v-card-text class="mt-4">
          <v-form ref="docForm">
            <v-text-field
              flat
              outlined
              label="Title"
              v-model="docUp.name"
              :rules="[rules.required]"
            ></v-text-field>
            <v-textarea
              outlined
              label="Description"
              flat
              v-model="docUp.description"
              :rules="[rules.required]"
            ></v-textarea>
            <v-file-input
              v-model="docUp.file"
              ref="docUpI"
              outlined
              show-size
              label="Upload File"
              :rules="[rules.required, rules.fileSize]"
            ></v-file-input>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="secondary" text @click="docUploadDialog = false">
            Cancel
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="uploadFile"
            :loading="docAddLoading"
          >
            Uppload
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- view document -->
    <v-dialog
      v-model="docViewDialog"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
      class="ma-0 pa-0"
    >
      <v-card
        height="100%"
        v-if="selectedDoc"
        tile
        class="ma-0 pa-0"
        color="primary"
      >
        <v-toolbar dark flat height="50">
          <v-toolbar-title>{{ selectedDoc.name }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="docViewDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <iframe
          :src="selectedDoc.binary_file"
          style="
            width: 100%;
            height: calc(100% - 60px);
            border: none;
            margin: auto;
          "
        ></iframe>
      </v-card>
    </v-dialog>

    <!-- Delete doc confirmation dialog -->
    <v-dialog
      v-model="deleteDocDialog"
      persistent
      max-width="290"
      v-if="selectedDoc"
    >
      <v-card outlined>
        <v-card-title class="text-h5"> Confirmation Needed </v-card-title>
        <v-card-text>
          Are you sure you want to delete this Document? This action cannot be
          undone.
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="error"
            text
            @click="deleteFile(selectedDoc.uuid)"
            :loading="deleteDocLoading"
          >
            Delete Doc
          </v-btn>
          <v-btn color="primary" text @click="deleteDocDialog = false">
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
    
    
    <script>
import { mapState } from "vuex";
import { v4 as uuidv4 } from "uuid";
import {
  getVehicle,
  deleteVehicle,
  updateEmission,
  updateLicense,
  updateInsurance,
  oilChange,
  mechanicalService,
  changeDriver,
  disableVehicle,
  enableVehicle,
  updateMeterBox,
  syncOfflineVehiclesChanges,
} from "@/services/vehiclesService";

import {
  fetchVehicleDocuments,
  addVehicleDocuments,
  deleteVehicleDocuments,
} from "@/services/vehicleDocumentsService";

import { fetchAndStoreStaffData } from "@/services/preloadDataService";
export default {
  components: {
    //
  },
  computed: mapState({
    auth: (state) => state.auth.data,
  }),
  props: [
    //
  ],
  data: () => ({
    menu1: false,
    menu2: false,
    menu3: false,
    menu4: false,
    menu5: false,

    historyDialog: false,
    history: [],
    history_type: null,
    oldValue: null,
    rules: {},

    loading: false,
    dataLoading: false,
    deleteVehicleLoading: false,
    deleteVehicleDialog: false,
    vehicle: {},

    updateEmissionDialog: false,
    updateEmissionLoading: false,
    newEmissionDate: null,

    updateInsuranceDialog: false,
    updateInsuranceLoading: false,
    newInsuranceDate: null,

    updateLicenseDialog: false,
    updateLicenseLoading: false,
    newLicenseDate: null,

    newOilChangeDate: null,
    newOilChangeKm: null,
    oilChangeDialog: false,
    oilChangeLoading: false,

    newMechanicalServiceDate: null,
    newMechanicalServiceKm: null,
    mechanicalServiceDialog: false,
    mechanicalServiceLoading: false,

    drivers: [],
    newDriver: null,
    changeDriverDialog: false,
    changeDriverLoading: false,

    disableVehicleLoading: false,
    disableVehicleDialog: false,

    enableVehicleDialog: false,
    enableVehicleLoading: false,

    updateMeterBoxDialog: false,
    updateMeterBoxLoading: false,

    docViewDialog: false,
    selectedDoc: null,
    deleteDocLoading: false,
    deleteDocDialog: false,
    docAddLoading: false,
    docGetLoading: false,
    docUploadDialog: false,
    docUp: {
      uuid: "",
      name: "",
      description: "",
      file: null,
      binary_file: "",
    },
    documets: [],
  }),
  async created() {
    this.rules = {
      required: (value) => !!value || "Required.",
      alphabets: (value) => {
        if (!value) return true;
        return (
          /^[A-Za-z\s]+$/.test(value) ||
          "Invalid input; only alphabetic letters are allowed."
        );
      },
      fileSize: (value) => {
        if (!value) return true;
        return value.size <= 50 * 1024 || "File size must be less than 50 KB.";
      },
      sameValue: (value) => {
        return value != this.oldValue || "New value must be different.";
      },
      pastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate < today || "The date must be in the past.";
      },
      todayOrFutureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate >= today || "The date must be today or in the future.";
      },
      futureDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate > today || "The date must be in the future.";
      },
      todayOrPastDate: (value) => {
        if (!value) return true;
        const inputDate = new Date(value);
        const today = new Date();
        inputDate.setHours(0, 0, 0, 0);
        today.setHours(0, 0, 0, 0);
        return inputDate <= today || "The date must be today or in the past.";
      },
    };

    if (
      this.$_checkPermission(this.auth.permissions, "Manage Vehicle", "Read")
    ) {
      this.loading = true;
      await syncOfflineVehiclesChanges();
      this.docGetLoading = true;
      let getVehicleReturn = await getVehicle(this.$route.params.uuid);
      this.vehicle = getVehicleReturn;
      this.loading = false;
      if (
        this.$_checkPermission(
          this.auth.permissions,
          "Manage Vehicle Document",
          "Read"
        )
      ) {
        this.getFiles();
      } else {
        this.docGetLoading = false;
      }
    } else {
      this.$router.push({
        name: "PageDashboard",
      });
    }
  },
  mounted() {
    //
  },
  methods: {
    goBack() {
      this.$router.push({ name: "PageVehicleList" });
    },
    editItem() {
      this.$router.push({
        name: "PageEditVehicle",
        params: { uuid: this.vehicle.uuid },
        query: { bc: this.vehicle.vehicle_no },
      });
    },
    async reFetchVehicle() {
      this.loading = true;
      await syncOfflineVehiclesChanges();
      let getVehicleReturn = await getVehicle(this.$route.params.uuid);
      this.vehicle = getVehicleReturn;
      this.loading = false;
    },
    async deleteVehicle() {
      let data = {
        uuid: this.vehicle.uuid,
      };
      this.deleteVehicleLoading = true;
      let deleteVehicleReturn = await deleteVehicle(data);
      this.deleteVehicleLoading = false;
      if (deleteVehicleReturn == "success") {
        this.deleteVehicleDialog = false;
        this.goBack();
      }
    },
    async updateEmissionX() {
      if (this.$refs.emissionForm.validate()) {
        this.updateEmissionLoading = true;
        let updateEmissionReturn = await updateEmission({
          uuid: this.vehicle.uuid,
          emission_test_date: this.newEmissionDate,
        });
        this.updateEmissionLoading = false;

        if (updateEmissionReturn == "success") {
          this.updateEmissionDialog = false;

          this.$refs.emissionForm.reset();
          this.reFetchVehicle();
        }
      }
    },
    async updateInsuranceX() {
      if (this.$refs.insuranceForm.validate()) {
        this.updateInsuranceLoading = true;
        let updateInsuranceReturn = await updateInsurance({
          uuid: this.vehicle.uuid,
          insurance_date: this.newInsuranceDate,
        });
        this.updateInsuranceLoading = false;

        if (updateInsuranceReturn == "success") {
          this.updateInsuranceDialog = false;

          this.$refs.insuranceForm.reset();
          this.reFetchVehicle();
        }
      }
    },
    async updateLicenseX() {
      if (this.$refs.licenseForm.validate()) {
        this.updateLicenseLoading = true;
        let updateLicenseReturn = await updateLicense({
          uuid: this.vehicle.uuid,
          revenue_license_date: this.newLicenseDate,
        });
        this.updateLicenseLoading = false;

        if (updateLicenseReturn == "success") {
          this.updateLicenseDialog = false;

          this.$refs.licenseForm.reset();
          this.reFetchVehicle();
        }
      }
    },
    async oilChangeX() {
      if (this.$refs.oilChangeForm.validate()) {
        this.oilChangeLoading = true;
        let oilChangeReturn = await oilChange({
          uuid: this.vehicle.uuid,
          oil_changed_date: this.newOilChangeDate,
          oil_changed_km: this.newOilChangeKm,
        });
        this.oilChangeLoading = false;

        if (oilChangeReturn == "success") {
          this.oilChangeDialog = false;

          this.$refs.oilChangeForm.reset();
          this.reFetchVehicle();
        }
      }
    },
    async mechanicalServiceX() {
      if (this.$refs.mechanicalServiceForm.validate()) {
        this.mechanicalServiceLoading = true;
        let mechanicalServiceReturn = await mechanicalService({
          uuid: this.vehicle.uuid,
          mechanical_serviced_date: this.newMechanicalServiceDate,
          mechanical_serviced_km: this.newMechanicalServiceKm,
        });
        this.mechanicalServiceLoading = false;

        if (mechanicalServiceReturn == "success") {
          this.mechanicalServiceDialog = false;

          this.$refs.mechanicalServiceForm.reset();
          this.reFetchVehicle();
        }
      }
    },
    async changeDriverX() {
      if (this.$refs.driverForm.validate()) {
        this.changeDriverLoading = true;
        let changeDriverReturn = await changeDriver({
          uuid: this.vehicle.uuid,
          driver_uuid: this.newDriver,
        });
        this.changeDriverLoading = false;

        if (changeDriverReturn == "success") {
          this.changeDriverDialog = false;

          this.$refs.driverForm.reset();
          this.reFetchVehicle();
        }
      }
    },
    async openDriverChange() {
      this.oldValue = this.vehicle.driver_uuid;
      this.changeDriverDialog = true;
      this.dataLoading = true;
      this.drivers = await fetchAndStoreStaffData();
      this.drivers.forEach((supervisor) => {
        supervisor["name"] = supervisor.first_name + " " + supervisor.last_name;
      });
      this.dataLoading = false;
    },
    async enableVehicleX() {
      this.enableVehicleLoading = true;
      let enableVehicleReturn = await enableVehicle({
        uuid: this.vehicle.uuid,
      });
      this.enableVehicleLoading = false;
      if (enableVehicleReturn == "success") {
        this.enableVehicleDialog = false;

        this.reFetchVehicle();
      }
    },
    async updateMeterBoxX() {
      this.updateMeterBoxLoading = true;
      let updateMeterBoxReturn = await updateMeterBox({
        uuid: this.vehicle.uuid,
      });
      this.updateMeterBoxLoading = false;
      if (updateMeterBoxReturn == "success") {
        this.updateMeterBoxDialog = false;

        this.reFetchVehicle();
      }
    },
    async disableVehicleX() {
      this.disableVehicleLoading = true;
      let disableVehicleReturn = await disableVehicle({
        uuid: this.vehicle.uuid,
      });
      this.disableVehicleLoading = false;
      if (disableVehicleReturn == "success") {
        this.disableVehicleDialog = false;

        this.reFetchVehicle();
      }
    },

    async getFiles() {
      this.docGetLoading = true;
      this.documets = await fetchVehicleDocuments(this.$route.params.uuid);
      this.docGetLoading = false;
    },
    async uploadFile() {
      if (this.$refs.docForm.validate()) {
        let reader = new FileReader();
        reader.readAsDataURL(this.docUp.file);
        reader.onload = async () => {
          this.docUp.binary_file = reader.result;
          this.docUp.uuid = uuidv4();
          this.docAddLoading = true;
          let addVehicleDocumentsReturn = await addVehicleDocuments(
            this.vehicle.uuid,
            this.docUp
          );
          this.docAddLoading = false;
          if (addVehicleDocumentsReturn == "success") {
            this.docUploadDialog = false;
            this.docUp = {
              uuid: "",
              name: "",
              description: "",
              file: null,
              binary_file: "",
            };
            this.getFiles();
          }
        };
      }
    },
    async deleteFile(uuid) {
      this.deleteDocLoading = true;
      let deleteVehicleDocumentsReturn = await deleteVehicleDocuments(
        this.vehicle.uuid,
        {
          uuid: uuid,
        }
      );
      this.deleteDocLoading = false;
      if (deleteVehicleDocumentsReturn == "success") {
        this.deleteDocDialog = false;
        this.getFiles();
      }
    },
    downloadBase64File(base64Data, fileName) {
      function extractMimeType(dataUrl) {
        const match = dataUrl.match(/^data:(.*?);base64,/);
        return match ? match[1] : "application/octet-stream";
      }

      let mimeType = "application/octet-stream";
      if (base64Data.startsWith("data:")) {
        mimeType = extractMimeType(base64Data);
      } else {
        const extension = fileName.split(".").pop().toLowerCase();
        const mimeTypes = {
          pdf: "application/pdf",
          txt: "text/plain",
          png: "image/png",
          jpg: "image/jpeg",
          jpeg: "image/jpeg",
          gif: "image/gif",
          svg: "image/svg+xml",
        };
        mimeType = mimeTypes[extension] || "application/octet-stream";
        base64Data = `data:${mimeType};base64,${base64Data}`;
      }

      const downloadLink = document.createElement("a");
      downloadLink.href = base64Data;
      downloadLink.download = fileName;
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    },
  },
};
</script>